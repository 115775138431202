import React, { Component } from 'react';

var renderPatents = patent => {
  let index = 0;
  const filepath = 'patents/'
  if (patent && patent.description) {
      return <li>
        {patent.description}
        {patent.patents.map(item => {
          const separator = index < patent.patents.length - 1 ? ', ' : ''
          index++;
          return <React.Fragment><a target="_blank" rel="noreferrer noopener" href={filepath + item.filename}>{item.number}</a>{separator}</React.Fragment>
        })}
        </li>
  }
}

class Resume extends Component {
  render() {

    if(this.props.data){
      var education = this.props.data.education.map(education => {
        return <div key={education.school}><h3>{education.school} <span className="location">{education.location}</span></h3>
        <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
        <p className="description">{education.description}</p></div>
      })
      var work = this.props.data.work.map(work => {
        return <div key={work.company}><h3>{work.company} <span className="location">{work.location}</span></h3>
            <p className="info">{work.title}
            <span>&bull;</span>
            <em className="date">{work.years}</em></p>    
            <ul className="description">
            {
              work.description.map(desc => {
                 return <li>{desc}</li>
              })
            }

            {work.uspatents ? renderPatents(work.uspatents) : null}
            {work.eupatents ? renderPatents(work.eupatents) : null}

            <li>{work.skills}</li>
            </ul>        
           
        </div>
      })
      var languages = this.props.data.skills.languages.map(language => {
        return <li key={language}>
          {language}
          </li>
      })
      var frameworks = this.props.data.skills.frameworks.map(framework => {
        // var className = 'bar-expand '+skills.name.toLowerCase();
        return <li key={framework}>
          {framework}
          </li>
      })
      var tools = this.props.data.skills.tools.map(tool => {
        // var className = 'bar-expand '+skills.name.toLowerCase();
        return <li key={tool}>
          {tool}
          </li>
      })
    }

    return (
      <section id="resume">

       <div className="row work">

         <div className="three columns header-col">
            <h1><span>Experience</span></h1>
         </div>

         <div className="nine columns main-col">
          {work}
        </div>
    </div>

    <div className="row education">
         <div className="three columns header-col">
            <h1><span>Education</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 {education}
               </div>
            </div>
         </div>
      </div>

      <div className="row skill">

         <div className="three columns header-col">
            <h1><span>Skills</span></h1>
         </div>

         <div className="nine columns main-col">
				<div>
        <p class="skill-category">
          Languages and Scripts
            </p>

				   <ul className="languages">
					  {languages}
					</ul>
          <p class="skill-category">
            Frameworks, Libraries, and Technologies
            </p>
          <ul className="frameworks">
					  {frameworks}
					</ul>
          <p class="skill-category">Software and Tools
            </p>
          <ul className="tools">
					  {tools}
					</ul>
				</div>
			</div>
      </div>
   </section>
    );
  }
}

export default Resume;
